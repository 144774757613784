import React, { useContext } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { LocalContext } from 'context';
import { motion } from 'framer-motion';
import Poll from 'assets/svgs/Poll';
import QAndA from 'assets/svgs/QAndA';
import { defaultColors } from 'styles';
import { Button } from '../../components';

function HelpHub() {
  const { theme, selectedEvent } = useContext(LocalContext);
  const colors = selectedEvent?.colors || defaultColors;
  return (
    <Wrapper
      theme={theme}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ delay: 0.5, duration: 1 }}>
      <Content>
        <h2>Virtual Event Guidelines</h2>
        <h3>Guidelines will help you to easily navigate and use our virtual platform</h3>
        <Options>
          <Option theme={theme}>
            <QAndA />
            <div>
              <h4>Q&A Guidelines</h4>
              <p>Learn how to interact with users through Q&A feature</p>
              <Link to="/help-hub/q-and-a">
                <Button type="button" width="124px" colors={colors}>
                  VIEW
                </Button>
              </Link>
            </div>
          </Option>
          <Option theme={theme}>
            <Poll />
            <div>
              <h4>Poll Guidelines</h4>
              <p>Learn how to use polls feature</p>
              <Link to="/help-hub/polls">
                <Button type="button" width="124px" colors={colors}>
                  VIEW
                </Button>
              </Link>
            </div>
          </Option>
        </Options>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  align-items: center;
  color: ${({ theme }) => theme.bg};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 6.75rem;
  text-align: center;
  width: 100vw;
  h2 {
    margin: 0.5rem;
    padding: 0.5rem;
  }
  h3 {
    font-weight: normal;
    margin-bottom: 2.625rem;
    text-align: center;
    width: 50vw;
  }
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 3rem 0;
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: none;
  grid-template-rows: 1fr 1fr;
  margin: 1rem;
  width: 100%;
  @media only screen and (min-width: 1024px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: none;
  }
`;

const Option = styled.div`
  background: rgba(0, 149, 255, 0.1);
  box-shadow: 0px 6px 20px rgba(196, 196, 196, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 1rem;
  max-height: 440px;
  max-width: 35.75rem;
  min-width: 25.75rem;
  > svg {
    align-self: center;
    margin: 2.5rem;
  }
  > div {
    background: ${({ theme }) => theme.contrastTextColor};
    display: flex;
    flex-direction: column;
    height: auto;
    justify-content: center;
    padding: 1.75rem;
    text-align: left;
    h4,
    p {
      padding-bottom: 0.5rem;
    }
    h4 {
      color: ${({ theme }) => theme.fg};
      font-size: 1.25rem;
      font-weight: 700;
    }
    p {
      font-size: 1.25rem;
    }
    button {
      margin-top: 1.25rem;
      a {
        color: ${({ theme }) => theme.contrastTextColor};
      }
    }
  }
`;

export default HelpHub;
